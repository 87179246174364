<template>
  <div class="manager-container">
    <template>
      <div class="flex-between-row">
        <h1 class="div-main-view-title">{{ pageTitle }}</h1>
      </div>
      <el-row class="shadow-border-bottom" type="flex">
        <div class="button-block">
          <div class="query-item search-bar">
            <el-input class="round-input" placeholder="Search By Barge, Vessel or Terminal" v-model="jobSearchVal"
              @keypress.enter.native="getJobTableData()">
              <el-button type="primary" slot="append" icon="el-icon-search" @click="getJobTableData()"></el-button>
            </el-input>
          </div>
        </div>
      </el-row>
      <div class="table-block margin-top-0" :style="{ height: 'calc(100% - 74px)' }">
        <table-job hasTableMenu hasPagination :isLoading="isLoading"
          :jobTableData="jobTableData" :jobPagination="jobPagination"
          @handleJobPageChange="handleJobPageChange" @handleJobPageSizeChange="handleJobPageSizeChange"
          @handleJobTableRefresh="handleJobTableRefresh" @handlePushToMpa="handlePushToMpa" />
      </div>
    </template>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import { TERM_CONTRACT_TYPE, ORDER_TYPE } from '@/constants.js'
import { mapState, mapActions } from 'vuex'
import TableJob from './components/TableJob.vue'

const JOB_TYPES = {
  1: "Delivery",
  2: "Loading",
  3: "STS",
};

export default {
  name: "mpaTransactionHistory",
  mixins: [baseMixin, baseTableMixin],
  components: {
    TableJob,
  },
  inject: ["reload"],
  data() {
    const jobPagination = {
      pageSize: 20,
      pageNumber: 0,
      total: 0
    }
    const jobFilter = {
      date: [],
      type: '',
      termContractType: '',
      bargeImo: ''
    }
    return {
      jobTableData: [],
      jobFilter,
      jobSearchVal: '',
      TERM_CONTRACT_TYPE,
      data: {
        curPage: 1,
        limit: 20,
        payTime1: null, // 收入日期1
        payTime2: null, // 收入日期2
      },
      JOB_TYPES,
      activeName: "0",
      pageTitle: "",
      jobPagination,
      isJobDataLoading: true,
      pushToMpaLoading: false,
    }
  },
  computed: {
    ...mapState({
    }),
    isLoading() {
      return this.isJobDataLoading || this.pushToMpaLoading
    }
  },
  created() {
    this.pageTitle = this.$route?.name;
    this.getShipList({});
    this.getJobTableData()
  },
  methods: {
    ...mapActions(["getShipList"]),

    getJobTableData(pageSize = this.jobPagination.pageSize, pageNumber = 0, query = null) {
      this.isJobDataLoading = true

      const filter = this.jobSearchVal ? `&filter=(vesselName ~'*${this.jobSearchVal}*' or bargeName ~'*${this.jobSearchVal}*' or locationLevel1 ~'*${this.jobSearchVal}*' or floaterName ~'*${this.jobSearchVal}*')` : ''
      this.$request
        .get({
          url: `/ebdn/jobs/mpa_transactions?pageSize=${pageSize}&pageNumber=${pageNumber}${filter ?? ''}&sort=updatedAt desc`
        })
        .then((res) => {
          if (res?.code === 1000) {
            this.jobTableData = res.data.content.map((item) =>
              Object.assign({}, item, {
                datetime:
                  item.type === 2
                    ? item.loadingTime
                      ? item.loadingTime
                      : ''
                    : item.vesselEta
                      ? item.vesselEta
                      : '',
                locationLevel: [item.locationLevel1, item.locationLevel2],
                terminal: item.type === 2 ? item.locationLevel1 : '',
                quantity:
                  (item.type === 2 ? item.loadingQty : item.finalQty) +
                  item.qtyUnitMetric,
                typeDisplay: this.generateJobTypeDisplay(item),
                orderDateDisplay: `${item.stemStartDate
                  ? this.$moment(item.stemStartDate).format('YYYY-MM-DD')
                  : ''
                  }\n${item.stemEndDate
                    ? this.$moment(item.stemEndDate).format('YYYY-MM-DD')
                    : ''
                  }`
              })
            )
            this.jobPagination.total = res.data.totalElements
            this.jobPagination.pageNumber = res.data.pageable.pageNumber + 1
          }
        }).finally(() => {
          this.isJobDataLoading = false
        })
    },

    generateJobTypeDisplay(job) {
      const withOrder = job.orderId !== null && job.orderId !== undefined
      const ownedJob = (this.currentCompany !== null && this.currentCompany !== undefined) &&
        ((this.currentCompany.id === job.mitigatingCompanyOrgId) || (this.currentCompany.id === job.sellerSysOrganizationId) || (this.currentCompany.id === job.buyerSysOrganizationId))

      if (withOrder && ownedJob) {
        if (job.orderType === ORDER_TYPE.BARGING_NOMINATION) {
          if (job.termContractType) {
            return job.termContractType === TERM_CONTRACT_TYPE.COA ? `${this.JOB_TYPES[job.type]} - B (COA)` : `${this.JOB_TYPES[job.type]} - B (TC)`
          } else {
            return `${this.JOB_TYPES[job.type]} - B`
          }
        } else {
          if (job.termContractType) {
            return `${this.JOB_TYPES[job.type]} - Term`
          } else {
            return this.JOB_TYPES[job.type]
          }
        }
      }

      return this.JOB_TYPES[job.type]
    },

    handleJobPageChange(pageNumber) {
      this.getJobTableData(this.jobPagination.pageSize, pageNumber - 1)
    },

    handleJobPageSizeChange(pageSize) {
      this.getJobTableData(pageSize, 0)
    },

    handleJobTableRefresh() {
      this.getJobTableData(this.jobPagination.pageSize, 0)
    },

    handlePushToMpa (jobId) {
      this.pushToMpaLoading = true
      this.$request.post({
        url: `${this.$apis.createNewJob}/${jobId}/push_to_mpa`
      }).then((res) => {
        if (res?.code === 1000) {
          this.$message.success(`${this.$t('mpaPushEbdnSuccess')}`)
        }
      }).finally(() => {
        this.getJobTableData(this.jobPagination.pageSize, this.jobPagination.pageNumber - 1)
        this.pushToMpaLoading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.manager-container {
  height: 100%;
  width: 100%;

  /deep/ .el-loading-spinner i {
    font-size: 30px;
  }

  .handler-bar {
    /deep/ .el-tabs__nav-wrap::after {
      background-color: transparent;
    }

    /deep/ .el-input__inner {
      border-color: #eaecef;
    }

    /deep/ .el-tabs__nav-next,
    /deep/ .el-tabs__nav-prev {
      font-size: 12px;
      // color: #0f376a;
    }
  }

  .button-block {
    @include flex-center-row;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;

    .query-item {
      display: inline-flex;
      align-items: center;
      margin: 10px 0 10px 15px;
    }

    .button-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.button-popover {
  @include flex-center-row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;

  .button-item {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    color: #4a4d54e6;
    padding: 0 12px;

    i {
      font-size: 21px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 0 0px 0;
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.more-button {
  font-size: 18px;
  padding: 5px 0;
}

.filter-line {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 23% 23% 23% 30%;

}

.div-main-list-page-table {
  height: calc(100% - 32px);
  text-align: right;
}
</style>
